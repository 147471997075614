<script setup lang="ts">
import { Dayjs } from "dayjs";
import type { KeenSliderOptions } from "keen-slider";

import type { EventData, Promotion } from "@/types";

const { t } = useT();
const { data: appInitData } = useAppInitData();
const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto" },
	created(slider) {
		setTimeout(() => {
			slider.update();
		}, 1500);
	}
};

const { isDesktop } = useDevice();
const router = useRouter();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const { handleOpen: handleMagicBoxesOpen } = useMagicBoxesController();

const { data: appInit } = useAppInitData();
const {
	refresh: refreshPromotionsData,
	homePromotions,
	magicBoxPromotion,
	piggybankPromotion,
	challengeSeasonPromotion,
	seasonPromotion,
	openDeposit,
	openCash,
	refreshHomeData,
	specialMagicBoxPromotion,
	controlHidePromotions
} = usePromotionsData();
const { isSweepStakes } = useSwitchMode();
const { open } = useTaoModals();
const {
	restTournamentData,
	loadingSubscribtions,
	subscribedStatus,
	entriesSum,
	coinsSum,
	activeStatus,
	handleTournament,
	durationLeft,
	tournamentData
} = useTournamentData({ open });
const { refresh: refreshBonusesData, data: bonusesData } = useBonusesData();
const loginGuard = useLoginGuard();
const { piggyData, isActiveStatus, isFullStatus, readyToBreak, handlePiggyClick, refreshPiggyData } = usePiggy({
	open
});
const { isLimitOffer, openFlipTheCoin } = useFlipTheCoin();
const { openQuestTask } = useQuestTask();
const { data: questData } = useQuestData();
const { seasonInitData } = useSeasonsData({ immediate: false });
const { depositInfoData, durationLeft: depositDurationLeft } = useTaoDepositStreak();

const { add } = useSockets();
const { isSpecialBox } = useSpecialMagicBox();
const { totalTasksPrize } = useGetPageQuestData({ immediate: false });

const sliderRef = ref();

const magicPromotion = computed(() => (isSpecialBox.value ? specialMagicBoxPromotion : magicBoxPromotion));

const isShowPiggyBankPromoCard = computed(
	() => !!piggybankPromotion && isActiveStatus.value && appInitData.value?.moneyBox?.isActive
);

const checkDepositStreakActive = (value: number) =>
	!!appInit.value?.depositStreak?.isActive && value >= (depositInfoData.value?.minDepositValue ?? 0);

const handleRefreshPiggyData = () => {
	refreshPiggyData();
};

const handleMagicBoxClick = () => {
	loginGuard({
		success: () => {
			handleMagicBoxesOpen();
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

const checkPromoDate = (promotion: Promotion & { date?: Dayjs | string }) => {
	if (promotion.type === "quest") {
		return questData.value?.dayInfo?.end ?? "";
	}
	return promotion.date ?? "";
};

const handleFlipTheCoinClick = () => {
	loginGuard({
		success: () => {
			openFlipTheCoin();
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

const handleClickInfo = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			if (promotion.type === "quest") {
				navigateTo("/quest/");
			}
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

const handleInviteClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			const referralUrl = promotion.data?.referralUrl;
			if (referralUrl) {
				open("LazyOModalInviteFriends", { referralUrl });
			}
			dispatchGAEvent({ event: "click_button", location: "promotions", buttonName: "copy" });
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

const handleChallengeClick = () => {
	loginGuard({
		success: () => {
			dispatchGAEvent({
				event: "click_button",
				button_name: questData.value?.questInfo?.isAvailable ? "winter_challenge_popup" : "get_ticket",
				location: "promotions"
			});

			if (questData.value?.questInfo?.isAvailable) {
				open("LazyOModalSeasonGames");
				return;
			}
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

const handleClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			if (promotion.type === "quest") {
				return openQuestTask();
			}
			if (promotion.type === "verification") {
				return router.push(preparedLink("game", "/#verify"));
			}

			if (promotion?.data?.enabled) {
				dispatchGAEvent({ event: "click_button", location: "home", buttonName: "make_deposit" });
				window?.$store?.gaCash?.deposit?.({
					location: "home",
					step: "view_payments_window",
					activePackage: promotion?.data?.money
				});

				openCash(promotion);
			}
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

const handleOpenRulesPiggy = () => {
	open("LazyOModalPromoHowItWorks", { type: "piggyBank" });
};

const handlePromotionsRefresh = async () => {
	await refreshPromotionsData();
	await nextTick();
	sliderRef.value?.slider.update();
};

const handleSeasonClick = () => {
	loginGuard({
		success: () => {
			dispatchGAEvent({
				event: "click_button",
				button_name: seasonInitData.value?.isActive ? "season_popup" : "get_ticket",
				location: "promotions"
			});

			if (seasonInitData.value?.isActive) {
				open("LazyOModalSeasonTower");
				return;
			}
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

const getImgFullPath = (src: string) => {
	const isNuxtImg = src.includes("nuxt-img");
	return isNuxtImg ? src : `${baseImageUrl}${src}`;
};

add("promotionsPage", ({ action }: EventData<Promotion>) => {
	if (action === "update") {
		refreshHomeData();
		refreshPromotionsData();
	}
});

watch(
	[homePromotions, bonusesData],
	async () => {
		await nextTick();
		sliderRef.value?.slider.update();
	},
	{ immediate: true }
);
</script>

<template>
	<div class="header">
		<AText type="h8 h5-md" data-tid="promotions" as="h3">
			{{ t("Promotions") }}
		</AText>
		<NuxtLink to="/promotions/" data-tid="promo-view-all">
			<AText type="base" :modifiers="['underline', 'link']">{{ t("View all") }}</AText>
		</NuxtLink>
	</div>
	<section class="content">
		<ASlider v-if="homePromotions?.promotions" ref="sliderRef" :options="sliderOptions" class="row-cards">
			<div
				v-for="(card, index) in homePromotions.promotions"
				:key="index"
				class="keen-slider__slide"
				data-tid="promo-card"
			>
				<OPromotionsCardSkeleton v-if="'skeleton' in card" />
				<template v-else>
					<AAnimationHoverSlide v-if="card.type === 'depositStreak'" :active="isDesktop">
						<MPromotionDepositStreak
							class="promo-card"
							type="depositStreak"
							:image="getImgFullPath(card.package?.imagePopup || '')"
							:deposit-info="depositInfoData"
							:finished-at="depositDurationLeft"
						/>
					</AAnimationHoverSlide>
					<AAnimationHoverSlide v-else-if="card.type === 'scratchCards'" :active="isDesktop">
						<MPromotionScratchCards @more-info="navigateTo('/scratch-cards/')" />
					</AAnimationHoverSlide>
					<AAnimationHoverSlide v-else-if="card.type === 'invite'" :active="isDesktop">
						<MPromotionInvite
							class="promo-card"
							:image="card.package?.imagePopup || ''"
							type="invite"
							:howWorks="card.package?.howWorks || {}"
							:coins="card.package?.coins || 0"
							:entries="card.package?.entries || 0"
							:available="!!card.available"
							:coinsSum="card.data?.coinsSum || 0"
							:entriesSum="card.data?.entriesSum || 0"
							:friendsQualifies="card.data?.friendsQualifies || 0"
							:friendsInvited="card.data?.friendsInvited || 0"
							@click="handleInviteClick(card)"
						/>
					</AAnimationHoverSlide>
					<AAnimationHoverSlide
						v-else-if="card.type === 'tournament' && restTournamentData?.isActiveStatus"
						:active="isDesktop"
					>
						<MPromotionTournament
							class="promo-card"
							type="tournament"
							:image="getImgFullPath(restTournamentData?.data?.image || '')"
							:coins="coinsSum"
							:entries="entriesSum"
							:dateLeft="durationLeft"
							:dateStart="restTournamentData?.data?.start"
							:loading="loadingSubscribtions"
							:isSub="subscribedStatus"
							:isSweepStakes="isSweepStakes"
							:isActive="activeStatus"
							:title="tournamentData?.title || ''"
							@more-info="router.push('/tournaments/')"
							@subscribe="handleTournament"
						/>
					</AAnimationHoverSlide>
					<AAnimationHoverSlide v-else-if="card.type === 'flipTheCoin' && !isLimitOffer" :active="isDesktop">
						<MPromotionFlipTheCoin
							class="promo-card"
							:image="card.package?.imagePopup || ''"
							type="flipTheCoin"
							:title="card.package?.title || ''"
							:entries="card.package?.entries || 0"
							@click="handleFlipTheCoinClick"
							@on-count-down-stop="refreshBonusesData"
						/>
					</AAnimationHoverSlide>
					<AAnimationHoverSlide v-else-if="card.type === 'inviteQualification'" :active="isDesktop">
						<MPromotionInviteQualification
							class="promo-card"
							:image="card.package?.imagePopup || ''"
							type="inviteQualification"
							:coins="card.package?.coins || 0"
							:entries="card.package?.entries || 0"
							:title="card.package?.title || ''"
							:subTitle="card.package?.subTitle || ''"
							@click="openDeposit(card)"
						/>
					</AAnimationHoverSlide>
					<AAnimationHoverSlide v-else-if="card.type === 'quest' && card.data?.type === 'season'" :active="isDesktop">
						<MPromotionChallengeWinter
							class="promo-card"
							:image="challengeSeasonPromotion?.imagePopup || ''"
							type="season"
							:title="challengeSeasonPromotion?.title || ''"
							:entries="challengeSeasonPromotion?.entries || 0"
							:end="questData?.questInfo?.end || ''"
							:start="questData?.questInfo?.start || ''"
							:isAvailable="!!questData?.questInfo?.isAvailable"
							@click="handleChallengeClick"
						/>
					</AAnimationHoverSlide>
					<AAnimationHoverSlide v-else-if="card.type === 'season'" :active="isDesktop">
						<MPromotionSeason
							class="promo-card"
							:image="seasonPromotion?.imagePopup || ''"
							type="season"
							:title="seasonPromotion?.title || ''"
							:entries="seasonPromotion?.entries || 0"
							:end="card.data?.finishedAt || ''"
							:isAvailable="!!seasonInitData?.isActive"
							@click="handleSeasonClick"
						/>
					</AAnimationHoverSlide>

					<AAnimationHoverSlide
						v-else-if="card.type === 'prizeDrops' && !!appInitData?.prizeDrops?.isActive"
						:active="isDesktop"
					>
						<MPromotionPrizeDrops />
					</AAnimationHoverSlide>

					<AAnimationHoverSlide v-else-if="controlHidePromotions(card.type)" :active="isDesktop">
						<MPromotionItem
							class="promo-card"
							:type="card.type"
							:showAvailable="!!card.data?.promoOfferPreset?.availableTill || card.type === 'quest'"
							:enabled="card.data?.enabled || card.type === 'quest'"
							:image="getImgFullPath(card.package?.imagePopup || '')"
							:title="card.package?.title || ''"
							:subTitle="card.package?.subTitle || ''"
							:questTitle="questData?.questInfo?.title || ''"
							:coins="card.type === 'quest' ? totalTasksPrize.totalCoins : card.package?.coins || 0"
							:entries="card.type === 'quest' ? totalTasksPrize.totalEntries : card.package?.entries || 0"
							:piggyCoins="card?.data?.promoOfferPreset?.moneyBoxAmount || 0"
							:badge="card.badgeLabel"
							:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
							:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
							:money="card.data?.money"
							currencySymbol="$"
							:textTooltip="card.tooltipText"
							:magicBoxAvailable="bonusesData?.isMagicBoxAvailable"
							:magicBoxDate="bonusesData?.dailyChests?.reasonValue || ''"
							:questSubscribed="!!questData?.isSubscribed"
							:promotionInfo="card.hasPromotionInfo"
							:usualPrice="card.useualPrice"
							:date="checkPromoDate(card)"
							:showDeposit="checkDepositStreakActive(Number(card.data?.money))"
							:subType="card.data?.promoOfferPreset?.subType"
							@click="handleClick(card)"
							@on-info="handleClickInfo(card)"
							@on-count-down-stop="handlePromotionsRefresh"
						/>
					</AAnimationHoverSlide>
				</template>
			</div>

			<div v-if="bonusesData?.isMagicBoxAvailable && bonusesData.dailyChests?.visible" class="keen-slider__slide">
				<AAnimationHoverSlide :active="isDesktop">
					<MPromotionItem
						class="promo-card"
						type="magicBox"
						:showAvailable="false"
						:enabled="true"
						:image="getImgFullPath(magicPromotion?.imagePopup || '')"
						:title="magicPromotion?.title || ''"
						:subTitle="(bonusesData?.isMagicBoxAvailable && magicPromotion?.subTitle) || ''"
						:coins="magicPromotion?.coins || 0"
						:entries="magicPromotion?.entries || 0"
						currencySymbol="$"
						:date="0"
						:bestDeal="false"
						:mostPopular="false"
						:promotionInfo="true"
						:magicBoxAvailable="bonusesData?.isMagicBoxAvailable"
						:magicBoxDate="bonusesData?.dailyChests?.reasonValue || ''"
						:questSubscribed="!!questData?.isSubscribed"
						:textTooltip="magicPromotion.textTooltip"
						@click="handleMagicBoxClick"
					/>
				</AAnimationHoverSlide>
			</div>

			<div v-if="isShowPiggyBankPromoCard" class="keen-slider__slide">
				<AAnimationHoverSlide :active="isDesktop">
					<MPromotionPiggybank
						class="promo-card"
						type="piggyBank"
						:isAvailable="readyToBreak"
						:image="getImgFullPath(piggybankPromotion?.imagePopup ?? '')"
						:minPromoCost="piggyData?.promoOffersPresets?.money ?? 0"
						:complete="piggyData?.moneyBoxAmount ?? 0"
						:goal="piggyData?.maxAmount ?? 0"
						:isFull="isFullStatus"
						:timer-date="piggyData?.availableTimeToBreak ?? ''"
						:title="piggybankPromotion?.title ?? ''"
						@info="handleOpenRulesPiggy"
						@on-timer-stop="handleRefreshPiggyData"
						@choose-offer="handlePiggyClick"
					/>
				</AAnimationHoverSlide>
			</div>
		</ASlider>
	</section>
</template>

<style scoped lang="scss">
:deep() {
	.keen-slider {
		margin-top: gutter(-2.5);
		padding-top: gutter(1.5);
	}

	.keen-slider__slide {
		padding: 0 gutter(1);

		@include media-breakpoint-down(lg) {
			padding: 0 gutter(0.5);
		}

		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
}

.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: gutter(2.5);
	:deep(.keen-slider) {
		overflow-y: visible !important;
	}
}
.promotions-wrapper {
	padding: gutter(0) gutter(7) gutter(6.25);

	@include media-breakpoint-down(lg) {
		padding: gutter(2);
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: gutter(6.25);
		width: 100%;
		max-width: 1022px;
		margin: auto;
		z-index: 19;

		@include media-breakpoint-down(lg) {
			gap: gutter(5);
		}
	}

	.title {
		margin-bottom: gutter(-3.75);
	}
}

.row-cards {
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-down(lg) {
		justify-content: center;
	}

	* {
		box-sizing: border-box;
	}
}
</style>
